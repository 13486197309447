import { Component, Input } from '@angular/core';
import { TransactionType } from '@app/shared/models/transaction';

@Component({
  selector: 'leu-transaction-type-badge',
  templateUrl: './transaction-type-badge.component.html',
  styleUrl: './transaction-type-badge.component.scss'
})
export class TransactionTypeBadgeComponent {

  @Input() type: TransactionType;

  styles: { [key in TransactionType] : string } = {
    CENTER_RECHARGE: "badge-success text-white",
    CENTER_RECHARGE_SPAYG: "badge-success text-white",
    CENTER_RECHARGE_FREECREDIT: "badge-primary text-white",
    CENTER_RECHARGE_FREECREDIT_SPAYG: "badge-primary text-white"
  }

  constructor() { }

  ngOnInit(): void { }

}
