import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';

import { S1Module  } from '../s1/s1.module';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { ColorsService } from './colors/colors.service';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';
import { UserStateSelectComponent } from './components/user-state-select/user-state-select.component';
import { UserRoleSelectComponent } from './components/user-role-select/user-role-select.component';
import { CenterSelectComponent } from './components/center-select/center-select.component';
import { RechargeCenterModalComponent } from './components/recharge-center-modal/recharge-center-modal.component';
import { TransactionsModalComponent } from './components/transactions-modal/transactions-modal.component';
import { TransactionStatusBadgeComponent } from './components/transaction-status-badge/transaction-status-badge.component';
import { CenterStatusBadgeComponent } from './components/center-status-badge/center-status-badge.component';
import { CustomerCardsModalComponent } from './components/customer-cards-modal/customer-cards-modal.component';
import { CustomerWalletModalComponent } from './components/customer-wallet-modal/customer-wallet-modal.component';
import { CustomerWalletSectionComponent } from './components/customer-wallet-section/customer-wallet-section.component';
import { CustomerCardsSectionComponent } from './components/customer-cards-section/customer-cards-section.component';
import { CustomerTransactionsSectionComponent } from './components/customer-transactions-section/customer-transactions-section.component';
import { ServicePayResultComponent } from './components/service-pay-result/service-pay-result.component';
import { TransactionTypeBadgeComponent } from './components/transaction-type-badge/transaction-type-badge.component';

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        ToastrModule.forRoot(),
        S1Module.forRoot()
    ],
    providers: [
        ColorsService
    ],
    declarations: [
        FlotDirective,
        SparklineDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        UserStateSelectComponent,
        UserRoleSelectComponent,
        CenterSelectComponent,
        RechargeCenterModalComponent,
        TransactionsModalComponent,
        TransactionStatusBadgeComponent,
        CenterStatusBadgeComponent,
        CustomerCardsModalComponent,
        CustomerWalletModalComponent,
        CustomerWalletSectionComponent,
        CustomerCardsSectionComponent,
        CustomerTransactionsSectionComponent,
        ServicePayResultComponent,
        TransactionTypeBadgeComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        AccordionModule,
        AlertModule,
        ButtonsModule,
        CarouselModule,
        CollapseModule,
        BsDatepickerModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        RatingModule,
        TabsModule,
        TimepickerModule,
        TooltipModule,
        PopoverModule,
        TypeaheadModule,
        ToastrModule,
        FlotDirective,
        SparklineDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        S1Module,
        UserStateSelectComponent,
        UserRoleSelectComponent,
        CenterSelectComponent,
        RechargeCenterModalComponent,
        TransactionsModalComponent,
        TransactionStatusBadgeComponent,
        CenterStatusBadgeComponent,
        CustomerWalletModalComponent,
        CustomerCardsModalComponent,
        CustomerCardsSectionComponent,
        CustomerWalletSectionComponent,
        CustomerTransactionsSectionComponent,
        ServicePayResultComponent,
        TransactionTypeBadgeComponent
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule
        };
    }
}
