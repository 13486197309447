<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
    <!-- START navbar header-->
    <div class="navbar-header">
        <a class="navbar-brand" href="#/">
            <div class="brand-logo">
                <img class="img-fluid" src="assets/img/logo.png" alt="App Logo" />
            </div>
            <div class="brand-logo-collapsed">
                <img class="img-fluid" src="assets/img/logo-single.png" alt="App Logo" />
            </div>
        </a>
    </div>
    <!-- END navbar header-->
    <!-- START Left navbar-->
    <ul class="navbar-nav mr-auto flex-row">
        <li class="nav-item">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="nav-link d-none d-md-block d-lg-block d-xl-block" trigger-resize="" (click)="toggleCollapsedSideabar()">
                <em class="fas fa-bars"></em>
            </a>
            <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
            <a class="nav-link sidebar-toggle d-md-none" (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
                <em class="fas fa-bars"></em>
            </a>
        </li>
        <!-- START User avatar toggle-->
        <!-- <li class="nav-item d-none d-md-block">
            <a  class="nav-link" (click)="toggleUserBlock($event)">
                <em class="icon-user"></em>
            </a>
        </li> -->
        <!-- END User avatar toggle-->
        <!-- START lock screen-->
        <!-- <li class="nav-item d-none d-md-block">
            <a class="nav-link"  title="Lock screen" [routerLink]="'/lock'">
                <em class="icon-lock"></em>
            </a>
        </li> -->
        <!-- END lock screen-->
    </ul>
    <!-- END Left navbar-->
    <!-- START Right Navbar-->
    <ul class="navbar-nav flex-row">
        <!-- Search icon-->
        <!--<li class="nav-item">
            <a class="nav-link" (click)="openNavSearch($event)">
                <em class="icon-magnifier"></em>
            </a>
        </li>-->
        <!-- Fullscreen (only desktops)-->
        <!--<li class="nav-item d-none d-md-block">
            <a class="nav-link" #fsbutton (click)="toggleFullScreen($event)">
                <em class="fa fa-expand"></em>
            </a>
        </li>-->
        <!-- START Offsidebar button-->
        <!--<li class="nav-item">
            <a class="nav-link" (click)="toggleOffsidebar(); $event.stopPropagation()">
                <em class="icon-notebook"></em>
            </a>
        </li>-->
        <!-- END Offsidebar menu-->
        <!-- START Language button-->
        <li class="nav-item dropdown" dropdown>
            <a href="#" class="dropdown-toggle" dropdownToggle aria-expanded="false" style=" text-decoration: none;"><img id="imgNavSel" src="assets/img/lang/{{selectedLanguage}}.png" class="flagIcon"><!--<span id="lanNav{{lang.code}}"></span> <span class="caret"></span>--></a>
            <ul class="dropdown-menu" *dropdownMenu role="menu" style="min-width:auto !important;">
                <li *ngFor="let lang of langs"><a href="javascript:" id="nav{{lang.code}}"  style=" text-decoration: none;" class="language" (click)="setLang(lang.code)"> <img id="imgNav{{lang.code}}" src="assets/img/lang/{{lang.code}}.png" class="flagIcon">  <!--<span id="lanNav{{lang.code}}"></span>--></a></li>
            </ul>
        </li>
        <!-- END Language menu-->
        <!-- START Logout button-->
        <li class="nav-item">
            <a class="nav-link" (click)="logout()">
                <em class="icon-logout"></em>
            </a>
        </li>
        <!-- END Logout menu-->
    </ul>
    <!-- END Right Navbar-->

    <app-navsearch [visible]="getNavSearchVisible()" (onclose)="setNavSearchVisible(false)"></app-navsearch>

</nav>
<!-- END Top Navbar-->
