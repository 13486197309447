<s1-table #table [onlinePagination]="false">
  <thead header>
    <tr>
      <th>#</th>
      <th>{{ 'cardsModal.table.columns.nickname' | translate }}</th>
      <th>{{ 'cardsModal.table.columns.code' | translate }}</th> 
      <th>{{ 'cardsModal.table.columns.wallet' | translate }}</th>
      <th>{{ 'cardsModal.table.columns.credit' | translate }}</th> 
      <th>{{ 'cardsModal.table.columns.date' | translate }}</th> 
      <th>{{ 'cardsModal.table.columns.status' | translate }}</th>
    </tr>
  </thead>
  <tbody body>
    <tr *ngFor="let card of table.config.rows; let i = index">
      <td>{{ i | s1TableGeneralIndex: table }}</td>
      <td>{{ card.nickname }}</td>
      <td>{{ card.uid }}</td>
      <td>{{ card.wallet?.code }}</td>
      <td>{{ card.metadata?.credito | s1Currency : true }}</td>
      <td>{{ card.metadata?.enrolledOn | s1ReadableDate }}</td>
      <td><i class="{{ card.isDeleted ? 'text-danger fas fa-trash' : 'text-success fas fa-check' }}"></i></td>
    </tr>
  </tbody>
</s1-table>