import { Component, OnInit, ViewChild } from '@angular/core';
import { IS1SearchParamsJava, S1ButtonType, S1Modal, S1ModalSizes, S1Table, S1UIService } from '@app/s1';
import { ITransaction, TransactionStatus } from '@app/shared/models/transaction';
import { IChangeStatusParams, TransactionService } from '@app/shared/services/transaction.service';

@Component({
  selector: 'leu-transactions-modal',
  templateUrl: './transactions-modal.component.html',
  styleUrls: ['./transactions-modal.component.scss']
})
export class TransactionsModalComponent implements OnInit {

  @ViewChild('modal') modal: S1Modal;
  @ViewChild('table') table: S1Table;

  private idCenter: number;

  s1ButtonType = S1ButtonType;
  transactionStatus = TransactionStatus;

  constructor(private transactionService: TransactionService, private ui: S1UIService) { }

  ngOnInit(): void { }

  open(idCenter: number) {
    this.idCenter = idCenter;
    this.modal.open(S1ModalSizes.LG);
    this.search();
  }

  close() {
    this.modal.close();
  }

  search() {
    const params: IS1SearchParamsJava = {
      paging: true,
      page: this.table?.actualPage() -1, //?? 0,
      rows: this.table?.itemsPerPage() ?? 10,
      orderBy: "tsLastModified",
      direction: "DESC",
      filters: {
        CENTER: {
          id: this.idCenter
        }
      }
    }

    this.transactionService.search(params).subscribe(userPaginati => {

      this.table.updateData(userPaginati.transactions, userPaginati.paginationInfo)

    })
  }

  askConfirmation(transaction: ITransaction, toActive: boolean) {

    const question = 'transactions.modal.popup.' + (toActive ? 'askActive' : 'askDisable')
    const message = 'transactions.modal.popup.' + (toActive ? 'activated' : 'disabled')

    const params: IChangeStatusParams = {
      id: transaction.id,
      status: toActive ? TransactionStatus.ACTIVE : TransactionStatus.CANCELLED
    }

    this.ui.showDialogPopup(question).then(result => {

      if (result.value) {
        this.transactionService.changeStatus(params).subscribe(success => {

          if (success) {
            this.ui.showSuccessToast(message);
            this.search();
          }

        })
      }

    })

  }

}
