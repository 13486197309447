import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { S1ButtonType, S1Modal, S1ModalSizes, S1UIService } from '@app/s1';
import { ICenter } from '@app/shared/models/center';
import { RechargeType, TransactionType } from '@app/shared/models/transaction';
import { CenterService } from '@app/shared/services/center.service';
import { TransactionService, IRechargeParams } from '@app/shared/services/transaction.service';

@Component({
  selector: 'leu-recharge-center-modal',
  templateUrl: './recharge-center-modal.component.html',
  styleUrls: ['./recharge-center-modal.component.scss']
})
export class RechargeCenterModalComponent implements OnInit {

  @Output() recharged = new EventEmitter<boolean>();

  @ViewChild('modal') modal: S1Modal;

  s1ButtonType = S1ButtonType;
  rechargeType = RechargeType;

  rechargeForm: UntypedFormGroup;
  center: ICenter;
  idCenter: number;

  rechargeTypeSelected: RechargeType;

  constructor(private formBuilder: UntypedFormBuilder, private router: Router, private transactionService: TransactionService, private centerService: CenterService, private ui: S1UIService) { 

    this.rechargeForm = this.formBuilder.group({
      amount: [0.00, [Validators.required]],
      servicePay: [false, [Validators.required]]
    })

  }

  ngOnInit(): void { }

  open(center: ICenter) {

    this.rechargeTypeSelected = null;
    this.rechargeForm.reset();
    this.rechargeForm.controls.amount.patchValue(0);
    this.center = center;
    this.idCenter = this.center.id;
    this.loadDetails();

  }

  openSimple(id: number, type: RechargeType) {

    this.rechargeTypeSelected = type;
    this.rechargeForm.reset();
    this.rechargeForm.controls.amount.patchValue(0);
    this.center = null;
    this.idCenter = id;
    this.loadDetails();

  }

  private loadDetails() {

    this.centerService.detail(this.idCenter).subscribe(center => {

      this.center = center;
      this.rechargeForm.controls.servicePay.patchValue(this.center?.flgRechargeSPAYG);
      this.modal.open(S1ModalSizes.MD);

    })

  }

  close() {
    this.modal.close();
  }

  add(isPositive: boolean) {

    const actual = this.rechargeForm.controls.amount.value;

    this.rechargeForm.controls.amount.patchValue(actual + 1000 * (isPositive ? 1 : -1));

  }

  recharge() {

    const amount = this.rechargeForm.controls.amount.value;
    const servicePay = this.rechargeForm.controls.servicePay.value;
    let typeTransaction: TransactionType;
    
    if (this.rechargeTypeSelected == this.rechargeType.money) {
      typeTransaction = servicePay ? TransactionType.CENTER_RECHARGE_SPAYG : TransactionType.CENTER_RECHARGE;
    } else if (this.rechargeTypeSelected == this.rechargeType.time) {
      typeTransaction = servicePay ? TransactionType.CENTER_RECHARGE_FREECREDIT_SPAYG : TransactionType.CENTER_RECHARGE_FREECREDIT;
    }

    if (this.idCenter && amount > 0 && typeTransaction) {

      const params: IRechargeParams = {
        idCenter: this.idCenter,
        amount: Math.floor(amount * 100), // es 10€ -> 1000 , 10,50€ -> 1050 , 10,456€ -> 1045
        type: typeTransaction 
      }

      if (servicePay) {

        this.transactionService.rechargeServicePay(params).subscribe(servicePayPayment => {

          if (servicePayPayment.hostedPageUrl) {
            window.location.href = servicePayPayment.hostedPageUrl;
          }

        })

      } else {

        this.transactionService.recharge(params).subscribe(recharged => {

          if(recharged) {
            this.ui.showSuccessToast("rechargeModal.popup.recharged");
            this.recharged.emit(true);
            this.close();
          }
  
        })

      }

    }

  }

}
