import { ICenter } from "./center"
import { IWallet } from "./wallet"

export enum RechargeType {
  money,
  time
}

export enum TransactionType {
  CENTER_RECHARGE = "CENTER_RECHARGE",
  CENTER_RECHARGE_SPAYG = "CENTER_RECHARGE_SPAYG",
  CENTER_RECHARGE_FREECREDIT = "CENTER_RECHARGE_FREECREDIT",
  CENTER_RECHARGE_FREECREDIT_SPAYG = "CENTER_RECHARGE_FREECREDIT_SPAYG"
}

export enum TransactionStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  TIMEOUT = "TIMEOUT"
}

export interface ITransaction {
  amount: number
  center: ICenter
  id: number
  realAmount: number
  status: TransactionStatus
  tsCreated: Date
  tsLastModified: Date
  type: TransactionType,
  wallet?: IWallet 
}